import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

class WholesalePage extends Component {

  render() {

    return (

      <Layout isAlternate={true}>
        <SEO title="Wholesale"/>
        <section className="hero is-large has-yellow-background is-relative has-absolute-navbar">
        <img id="tincture-floating-box" src="https://reasontosmile.wpengine.com/wp-content/uploads/2019/11/Smile_Tincture_product_floating_box_mint@2x.png" style={{
          top: "8%",
          left: "6%",
          right: 0,
          width: "25%",
          position: "absolute"
        }} alt=""/>
        <img id="gummies-benefits" src="https://reasontosmile.wpengine.com/wp-content/uploads/2019/11/gummies_2x.png" style={{
          bottom: "-9%",
          right: "10%",

          width: "30%",
          position: "absolute"
        }} alt=""/>

          <div className="hero-body">

            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered has-text-light">

                  <h1 className="has-text-light">
                    We love partnership
                  </h1>
                  <p>Let’s spread the power of CBD, together. Whether you’re already selling CBD products or interested in getting started, we’re always open to discussing the possibilities.</p>
                </div>

              </div>
            </div>
          </div>

        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-one-third">
                <h2>
                  Want to carry Smile products?
                </h2>
                <h4>Cool. Fill out this short form if you’re interested in buying wholesale CBD products & we’ll be in touch.</h4>
              </div>
              <div className="column is-2"/>
              <div className="column">
              <div id="contact-form-container">

                <ContactForm isWholesale={true}/>
                </div>
              </div>

            </div>

          </div>

        </section>
      </Layout>
    )
  }
}

export default WholesalePage
